.Parent{
    display: flex;
  }
.Heading{
    font-weight: bolder;
    margin: 5%;

}
.ImgDiv{
    margin: 5%;
    border: 3px double black ;  
   
}

.DataRow{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.NoImg{
  width: 100%;
  height: 100%;  
  align-content: center;
  font-size:7vw;
}

.RestData{
  margin-left: 5%;  
}

@media only screen and (max-width: 768px) {
    .Heading{
        font-size: 5vw;
        
    }
    .Parent{
        flex-direction: column;

    }
    .ImgDiv{
        width:75vw;
        height:75vw;
    }

    .DataArea{
      width: 100%;
    }
    .DataRow{
        flex-direction: column;
        width: 100%;
    }
  }
@media only screen and (min-width: 768px) {
.Heading{
    font-size: 3vw;
}
  
.Parent{
    flex-direction: row;

} 
.DataRow{
 justify-content: space-between;
}

.DataArea{
    display: flex;
    flex-direction: column;  
    width:50%;
    }

    .ImgDiv{
        width:25vw;
        height:25vw;
    }
  }
