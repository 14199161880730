
:root{
 --optionWidth:86%;
 --marginLeft:7%;
 --Height:140px;
}

.Parent{
  display: flex;
  flex-direction: column;
  position: relative;
}
.SelectIcon{
    float: left;
    background-color:#E6EEFF;
    padding-left:7%;
    margin-right:7%;
    height:3vh;
    margin-top: 6%;
    color: rgba(0,0,0,0.5);
  
  }
  .Select{
    min-height: 35px;
    display: flex;
    flex-direction: row;
    background-color:#E6EEFF;
    width:80%;
    border-radius:5px 5px 0 0;
    margin-left:7%;
    padding-right:3%;
    padding-left:3%;
    margin-top:3%;
    height: 6vh;
    border-radius:5px;
    position: absolute;
  
  }
  .options{
    /* width:var(--optionWidth); */
    /* background-color: white; */
   
    /* height:auto; */
    /* margin-left:var(--marginLeft); */
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
    animation: fadeIn 0.6s ease-in; 
    /* position: fixed; */
    top:max(35px,6vh);
    background-color:white;
    font-size: large;
    color: black;
    margin-left:7%;
    margin-right:7%;
    height:auto;
    max-height: 500px;
    overflow-y: auto;
    outline:none;
    border: none;
    z-index:2;
    position: absolute;
    width:86%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    background-color: white;
  }
  
  @keyframes fadeIn {  
    from {  
        height:0;  
    }  
  
    to {  
        height:140px;  
    }  
  }
  
  @keyframes fadeOut {  
    from {  
        height:140px;  
  
    }  
  
    to {  
      height:0;  
    } 
     
  }
  
  
  @keyframes fadeIt {  
    from {  
        display:block;  
  
    }  
  
    to {  
      display:none;  
  
    } 
     
  }
  
  .fOut{
    width:86%;
    background-color: white;
    height:0;
    margin-left:7%;
    margin-right:3%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    animation: fadeOut 0.6s ease-in;
    /* position: fixed; */
    height:auto;
    /* margin-left:var(--marginLeft); */
    /* width:var(--optionWidth); */
    position: absolute;
    top:max(35px,6vh);


  }
  
  .SelectActive{
    display: flex;
    flex-direction: row;
    background-color:#E6EEFF;
    width:80%;
    border-radius:5px 5px 0 0;
    margin-left:7%;
    padding-right:3%;
    padding-left:3%;
    margin-top:3%;
    height: 6vh;
    min-height: 35px;
    position: absolute;


  }
  .TurnedIcon{
    padding-right: 2%;
    rotate: 180deg;
    width:6%;
    height: auto;
    opacity: 50%;
  }
  .NormalIcon{
    padding-right: 2%;
    width:6%;
    height: auto;
    opacity: 50%;
  }
  .option{
    padding-top:2%;
    padding-bottom:2%;
    margin: 0%;
  }
  .option:hover{
    background-color: #E6EEFF;
  }
  .optionText{
    padding-left: 3%;
  
  }
  .SelectedDiv{
    padding-right: 2%;
    width:94%;
    height: auto;
    display: flex;
    align-items: center;
    font-size: larger;
    float: center;
    text-align: center;
    opacity: 50%;
  }
  .Hide{
    display: none;
  }